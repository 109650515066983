<script>
import CarByCategorySelect from '@components/select/car-by-category';
import ContentLoading from '@components/content-loading';
import DriverLicenseAlert from '@components/shared/driver-license-alert.vue';
import LastBookingResume from '@components/modals/booking/last-booking-resume.vue';
import find from 'lodash/find';
import BOOKING_OPEN from '@graphql/booking/mutations/open.gql';
import BOOKING_UPDATE from '@graphql/booking/mutations/update.gql';
import BOOKING_CLOSE from '@graphql/booking/mutations/close.gql';
import BOOKING_OPTIONS from '@graphql/booking/queries/options.gql';
import GET_PLACE_BY_ID from '@graphql/place/queries/get.gql';
import GET_CLOSING_KINDS_BY_ID from '@graphql/booking/queries/get-booking-closing-kind-by-id.gql';
import GET_OPENING_KINDS_BY_ID from '@graphql/booking/queries/get-booking-opening-kind-by-id.gql';
import GET_OPENING_KINDS from '@graphql/booking/queries/get-booking-opening-kinds.gql';
import BOOKING_CANCEL from '@graphql/booking/mutations/cancel.gql';
import ModalCancelBooking from '@components/modals/cancel-booking';
import SCHEDULE_DELIVERY_CANCEL from '@graphql/schedules/mutations/cancel-schedule-delivery.gql';
import DRIVER_GET from '@graphql/driver/queries/get.gql';
import FINALLY_SCHEDULE_DEVOLUTION from '@graphql/schedules/mutations/finally-schedule-devolution.gql';
import { makeQuery } from '@graphql/middleware';
import { isMexico, isBrazil } from '@utils/helper-region';
import { formatDate } from '@utils/formatters/date';
import { PlanStatusEnum, PlanProductTypesEnum, PlanCatergoriesEnum } from '@enums/plan';
import { BookingOpeningKindEnum } from '@enums/booking';
import { formatPrice } from '@utils/numbers';
import { isBefore, subDays } from 'date-fns'
import NewUploadImage from '@components/shared/NewUploadImage';
import { mapGetters } from 'vuex';
import ModalFleetCompareSignatures from '@components/fleet/modals/fleet-compare-signatures';
import LicenseInfoEdit from '@components/fleet/fleet-license-info';
import UPDATE_BOOKING_INFO from '@graphql/booking/mutations/update-booking-info.gql';
import UNLINK_CAR from '@graphql/booking/mutations/unlink-car.gql';

const MIN_PIX_INSTALLMENT_AGE = 40

export default {
  name: 'ModalBookingModal',
  components: {
    CarByCategorySelect,
    ContentLoading,
    ModalCancelBooking,
    DriverLicenseAlert,
    LastBookingResume,
    NewUploadImage,
    ModalFleetCompareSignatures,
    LicenseInfoEdit,
    InputSelectSearch: () => import('@components/shared/input-select-search'),
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    modal_data: {
      type: Object,
      required: true,
      default: () => {},
    },
    allBookings: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {
      driverData: null,
      schedulePlace: null,
      driverScheduleDelivery: null,
      kindRuleLoading: false,
      selectedOpenKindRule: null,
      openedKinds: null,
      possibleKinds: [],
      openingKindRule: [],
      booking_options: {
        plans: {
          items: [],
        },
      },
      fieldState: null,
      saveClicked: false,
      data: {
        id: null,
        driver: null,
        plan: null,
        car: '',
        recurrency: null,
        has_recurrency: false,
        transaction_method: null,
        transaction_discount: 0,
        pre_transaction_method: null,
        pre_transaction_installments: 0,
        pre_transaction_discount: 0,
        started_at: null,
        finished_at: null,
        status: null,
        kindClosed: null,
        kindOpened: null,
        kind_opened_id: null,
      },
      modal: {
        title: '',
        save_button: '',
        cancel_button: '',
        close_booking_button: '',
      },
      bookingDays: 0,
      booking_options_disabled: true,
      options_pre_transaction_installments: [],
      options_payment_methods: [],
      options_frist_payment_methods: [],
      options_pre_payment_methods: [],
      block_ui: false,
      block_active: false,
      block_closed: false,
      block_pending: false,
      open_kinds: [],
      openKindOptions: [],
      block_installments: false,
      modalCancelBooking: false,
      region: '',
      planOptions: [],
      planFiltered: {},
      planStatusFilters: 'ALL',
      planProductTypeFilters: 'ALL',
      planCategoryFilters: 'ALL',
      isZeroKmFilter: false,
      selectPlanDisabled: false,
      carCategory: null,
      isValidOpenKind: undefined,
      selectedOpenKind: null,
      isPlanFromCheckboxOption: false,
      warnOpenKindMessage: '',
      kind_rules: [],
      changeLinkCar: false,
      update_booking_mutation: UPDATE_BOOKING_INFO,
      unlinkCarMutation: UNLINK_CAR,
      block_ui_by_driver_ocr: false,
      koviStaff: '',
    };
  },
  computed: {
    ...mapGetters({
      bookingSelect: 'driver/bookingSelect',
      driverDocuments: 'driverDocument/address',
    }),
    images() {
      let source = null;
      if (this.bookingSelect?.id) {
        source = `${this.bookingSelect?.id}_${this.getCurrentTimestamp()}`;
      }
      const addressProof = this.driverDocuments?.find(item => item.type === 'ADDRESS')
      const uploadImagesProps = {
        contrato: {
          label: 'contrato',
          keyItem: 'contrato',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.contract || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'contract',
            source,
            fileKey: 'file',
          },
        },
        cnh: {
          label: this.$t('drivers.labels.license_number'),
          keyItem: 'cnh',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.driver?.cnh_doc?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'cnh_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
        crlv: {
          label: 'crlv',
          keyItem: 'crlv',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: this.bookingSelect?.car?.document_url || null,
          endpoint: '/docs/upload-generic',
          uploadProps: {
            type: 'car_document',
            source: this.bookingSelect?.car?.id || null,
            fileKey: 'file',
          },
        },
        address: {
          label: this.$t('drivers.labels.addressProof'),
          keyItem: 'address_photo',
          caption: this.$t('drivers.labels.clickInToBox'),
          item: addressProof?.url || null,
          endpoint: '/docs',
          accept: 'image/*,.pdf',
          uploadProps: {
            documentType: 'address_photo',
            driverId: this.bookingSelect?.driver?.id || null,
            fileKey: 'data',
          },
        },
      };

      if (this.isRegionBrazil()) delete uploadImagesProps.crlv;

      return uploadImagesProps;
    },
    cannotSave() {
      if (this.isRegionMexico() || this.bookingSelect?.status !== 'PENDING') return false;
      return (
           !this.bookingSelect?.id
        || !this.bookingSelect?.contract
        || !this.bookingSelect?.driver?.cnh_doc?.url
        || !this.driverData.license_expired_at
        || !this.driverData.license_number
        || !this.data.car
      );
    },
    getBookingSelectId() {
      return this.bookingSelect?.id || null;
    },
    getDriverDocumentUrl() {
      const cnhDocUrl = this.bookingSelect?.driver?.cnh_doc?.url;
      if (!cnhDocUrl) return null;
      return `${cnhDocUrl}?t${+new Date()}`;
    },
    getContractUrl() {
      const contractUrl = this.bookingSelect?.contract;
      if (!contractUrl) return null;
      return `${contractUrl}?t${+new Date()}`;
    },
    canSaveBooking() {
      if (this.isRegionMexico()) return true;
      return this.isDriverLicenseValid();
    },
    can_change_car_category() {
      if (this.isRegionBrazil()
          && ['FIRST_LOCATION', 'NEW_LOCATION'].includes(find(this.openedKinds, { id: this.data.kind_opened_id })?.type)
        ) return this.$store.getters['user/groups'].includes('RULE:BOOKING:CAR_CHANGE_CATEGORY');
      return true;
    },
    isLastBookingClosedOver15Days() {
      const lastBooking = this.allBookings[0];
      const isClosed = lastBooking.status === 'CLOSED'
      const isOver15Days = isBefore(new Date(lastBooking.finished_at), subDays(new Date(), 15))

      return isClosed && isOver15Days
    },
    plan_status_filters() {
      return [PlanStatusEnum.ACTIVE, PlanStatusEnum.PAUSED, 'ALL'].map(item => ({ text: this.$i18n.t(`modalBooking.filters.status.${item}`), value: item }));
    },
    plan_products_types_filters() {
      return [
        PlanProductTypesEnum.KOVI_MENSAL,
        PlanProductTypesEnum.KOVI_ANUAL,
        PlanProductTypesEnum.KOVI_PROPRIO,
        PlanProductTypesEnum.KOVI_PROPRIO_2,
        'ALL',
      ].map(item => ({
        text: this.$i18n.t(`modalBooking.filters.productType.${item}`),
        value: item,
      }));
    },
    plan_categories_filters() {
      return [
        PlanCatergoriesEnum.SEDAN_PREMIUM,
        PlanCatergoriesEnum.SEDAN_BASICO,
        PlanCatergoriesEnum.HATCH_BASICO,
        PlanCatergoriesEnum.HATCH_ECONOMICO,
        PlanCatergoriesEnum.SUV_BASICO,
        PlanCatergoriesEnum.HATCH_COMFORT,
        PlanCatergoriesEnum.OTHERS,
        'ALL',
      ].map(item => ({ text: this.$i18n.t(`modalBooking.filters.category.${item}`), value: item }));
    },
    disableInstallmentTooltipText() {
      if (isMexico()) return true;
      if (!this.data.pre_transaction_method || !this.planFiltered?.id || !this.data.kind_opened_id) return true;

      const paymentMethodData = find(this.booking_options.paymentMethods.items, {
        id: this.data.pre_transaction_method,
      });

      if (paymentMethodData.type !== 'pix') return true;
      if (!this.driverIsOlderToPixInstallment()) return false;

      const isRTO = this.isRTO(this.planFiltered);
      const pixInstallments = isRTO
        ? this.planFiltered?.kind_configurations.PRE_PAYMENT_RTO.pix_installments || 1
        : this.planFiltered?.kind_configurations.PRE_PAYMENT.pix_installments || 1;

      const kindOpenedSelected = find(this.openedKinds, { id: this.data.kind_opened_id })?.type;
      const kindOpeningAllowPixInstallments = ['FIRST_LOCATION', 'NEW_LOCATION'].includes(kindOpenedSelected);
      const allowsPixInstallments = pixInstallments > 1 && kindOpeningAllowPixInstallments;

      return !allowsPixInstallments;


    },
    tooltipText() {
      if (!this.planFiltered?.id || !this.data.pre_transaction_method) return '';

      return this.driverIsOlderToPixInstallment()
        ? this.minimumAgeTooltipText()
        : this.installmentTooltipText()
    },
  },
  watch: {
    planOptions() {
      const filteredPlan = this.planOptions?.find(plan => plan.id === this.modal_data.plan);
      if (filteredPlan) this.planFiltered = filteredPlan;
    },
    isZeroKmFilter() {
      this.planOptions = this.orderedPlans();
    },
    planFiltered() {
      this.isValidOpenKind = this.checkOpenKindRules(this.selectedOpenKind)
    },
    selectedOpenKind() {
      this.isValidOpenKind = this.checkOpenKindRules(this.selectedOpenKind)
      this.warnOpenKindMessage = this.openKindWarnMessage(this.selectedOpenKind)
    },
    kind_rules() {
      if (this.modal_data?.plan) {
        this.isRTO(this.modal_data?.plan) ? this.setAvailablePrePaymentMethods('PRE_PAYMENT_RTO') : this.setAvailablePrePaymentMethods();
        this.setAvailableFristPaymentMethods()
      }
    }
  },
  mounted() {
    this.koviStaff = this.$store.getters['user/attributes'].email;
    this.$root.$on('signatureMatched', (res) => {
      this.block_ui = false;
      if (res && this.modal_data.status === 'PENDING') this.handleSubmit()
    })
    this.verifyCountry();
  },
  apollo: {
    open_kinds: {
      query: GET_OPENING_KINDS,
      update: data => data,
      result(data) {
        if (!data.loading) {
          const openingKinds = data.data.getBookingOpeningKinds.items;
          this.openedKinds = openingKinds;
          const openingKindsByCategory = this.groupByCategory(openingKinds);
          const categories = this.getSortedOpenKindCategories();

          this.openKindOptions = categories.map(category => {
            return {
              label: this.$i18n.t(`modalBooking.enumGroupOpeningReason.${category}`) ?? category,
              options: openingKindsByCategory[category].map(kind => ({
                value: kind.id,
                text: kind.translate[this.region],
              })),
            };
          });
        }
      },
    },
    booking_options: {
      query: BOOKING_OPTIONS,
      update: data => data,
      result(data) {
        if (!data.loading) {
          this.formatPlanNames(data.data.plans.items);
          this.options_payment_methods = this.generatePaymentOptions(data.data.paymentMethods.items);
          this.kind_rules = data.data.getAvailablePaymentMethods;
          this.planOptions = this.orderedPlans();
          this.carCategory = this.getCarCategoryByPlan(this.modal_data.plan);
        }
      },
      variables() {
        return {
          driver: this.$route.params.id,
          kinds: ['PRE_PAYMENT', 'PRE_PAYMENT_RTO', 'FIRST_PAYMENT'],
          filters: {
            order: [['created_at', 'DESC']],
          },
        };
      },
      skip() {
        return this.booking_options_disabled;
      },
    },
    driver: {
      query: DRIVER_GET,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(data) {
        if (data.data) {
          this.driverData = data.data.driver;
          this.getScheduleDelivery();
        }
      },
    },
  },
  methods: {
    enableDisableSaveButton(value) {
      this.block_ui_by_driver_ocr = value;
    },
    checkOpenKindRules(kind) {
      if (!this.planFiltered?.id) return null;

      const hasAtLeastOneBookingClosed = this.allBookings.some(booking => booking.status === 'CLOSED')
      const isSamePlanFromLastBooking = this.isPlanFromCheckboxOption

      switch (kind.type) {
        case BookingOpeningKindEnum.FIRST_LOCATION:
          return !hasAtLeastOneBookingClosed
        case BookingOpeningKindEnum.NEW_LOCATION:
          return hasAtLeastOneBookingClosed && !isSamePlanFromLastBooking
        case BookingOpeningKindEnum.TITULAR_VEHICLE_DELIVER:
        case BookingOpeningKindEnum.RESERVATION_DELIVER:
          return hasAtLeastOneBookingClosed && isSamePlanFromLastBooking
        default:
          return hasAtLeastOneBookingClosed
      }
    },
    openKindWarnMessage(kind) {
      if (kind.type === BookingOpeningKindEnum.NEW_LOCATION && !this.isLastBookingClosedOver15Days) {
        return this.$i18n.t('modalBooking.texts.warningOpenKindBeforeChurn');
      }
      if (kind.type !== BookingOpeningKindEnum.NEW_LOCATION && this.isLastBookingClosedOver15Days) {
        return this.$i18n.t('modalBooking.texts.warningOpenKindAfterChurn');
      }
      return ''
    },
    dateFormat(date) {
      return formatDate(date);
    },
    getScheduleDelivery() {
      this.driverScheduleDelivery = this.driverData.schedule.filter(item => item.kind === 'DELIVERY' && item.status === 'SCHEDULED')[0];
      this.$apollo
        .query({
          query: GET_PLACE_BY_ID,
          variables: {
            id: this.driverScheduleDelivery.place,
          },
        })
        .then(({ data: { place } }) => {
          this.schedulePlace = {
            name: place.name,
            address: {
              street: place.address_street_name,
              number: place.address_street_number,
              neighborhood: place.address_neighborhood,
              city: place.address_city,
              state: place.address_state,
            },
          };
        });
    },
    getSortedOpenKindCategories() {
      return ['SALES', 'TEMPORARY_EXCHANGE', 'PERMANENT_EXCHANGE', 'PLANS'];
    },
    groupByCategory(openingKinds) {
      return openingKinds.reduce((result, openingKind) => {
        const kindsByCategory = (result && result[openingKind.category]) ?? [];
        kindsByCategory.push(openingKind);
        result[openingKind.category] = kindsByCategory;
        return result;
      }, {});
    },
    checkField(value) {
      return this.saveClicked ? !!value : null;
    },
    onOpenKindChange(id) {
      this.kindRuleLoading = true;
      const selectedOpeningKind = this.openedKinds.find(item => item.id === id);
      this.selectedOpenKind = selectedOpeningKind;

      const rules = selectedOpeningKind.configs.rule;
      const keys = Object.keys(rules);

      this.setPaymentMethod(this.data.pre_transaction_method)
      this.openingKindRule = keys.map(item => ({ key: item, value: rules[item] }));
      this.kindRuleLoading = false;
    },
    isRTO(plan) {
      return plan.product === 'RENT_TO_OWN';
    },
    setPaymentMethod(payment_method) {
      if (this.data.pre_transaction_method === null) this.data.pre_transaction_method = payment_method;
      if (this.isPlanFromCheckboxOption) this.setPaymentMethodFromLastBooking();
      if (payment_method && this.planFiltered?.id && !this.isPlanFromCheckboxOption) {
        const paymentMethodData = find(this.booking_options.paymentMethods.items, {
          id: payment_method,
        });

        const isRTO = this.isRTO(this.planFiltered);
        const kind = isRTO ? 'PRE_PAYMENT_RTO' : 'PRE_PAYMENT';
        const pixInstallments = isRTO
          ? this.planFiltered?.kind_configurations.PRE_PAYMENT_RTO.pix_installments || 1
          : this.planFiltered?.kind_configurations.PRE_PAYMENT.pix_installments || 1;

        const kindOpenedSelected = find(this.openedKinds, { id: this.data.kind_opened_id })?.type;
        const kindOpeningAllowPixInstallments = ['FIRST_LOCATION', 'NEW_LOCATION'].includes(kindOpenedSelected);
        const allowsPixInstallments = pixInstallments > 1
           && kindOpeningAllowPixInstallments
           && (this.driverIsOlderToPixInstallment() && !this.isPlanFromCheckboxOption);
           
        if (paymentMethodData.type === 'credit_card' || allowsPixInstallments) {
          const installmentVar = paymentMethodData.type === 'pix' ? 'pix_installments' : 'installments';
          let _installments = [];
          let i;
          for (i = 0; i < this.planFiltered.kind_configurations[kind][installmentVar]; i++) {
            _installments.push({
              value: i + 1,
              text: `${i + 1}x`,
            });
          }
          this.options_pre_transaction_installments = _installments;
          this.data.pre_transaction_installments = 1;
          this.block_installments = false;
        }
        if (paymentMethodData.type === 'pix' && !allowsPixInstallments) {
          this.options_pre_transaction_installments = [{ value: 1, text: `1x` }]
          this.data.pre_transaction_installments = 1;
          this.block_installments = true;
        }
        if (['boleto', 'oxxo'].includes(paymentMethodData.type)) {
          this.options_pre_transaction_installments = [{ value: 1, text: `1x` }]
          this.data.pre_transaction_installments = 1;
          this.block_installments = true;
        }
      }
    },
    setPaymentMethodFromLastBooking() {
      const lastBooking = this.allBookings[0]
      const paymentMethodData = find(this.booking_options.paymentMethods.items, {
        id: lastBooking.pre_transaction_method.id,
      });

      if (paymentMethodData
          && paymentMethodData.type === 'pix'
          && lastBooking.pre_transaction_installments > 1
          && (this.driverIsOlderToPixInstallment() || this.isPlanFromCheckboxOption)) {
        this.options_pre_transaction_installments = [{ value: lastBooking.pre_transaction_installments, text: `${lastBooking.pre_transaction_installments}x` }]
        this.data.pre_transaction_installments = lastBooking.pre_transaction_installments;
        this.data.pre_transaction_method = lastBooking.pre_transaction_method.id;
      } else {
        this.options_pre_transaction_installments = [{ value: 1, text: `1x` }]
        this.data.pre_transaction_installments = 1;
      }
    },
    installmentTooltipText() {
      const isRTO = this.isRTO(this.planFiltered);
      const prePaymentInstallmentAmount = isRTO
        ? this.planFiltered?.kind_configurations.PRE_PAYMENT_RTO.installment_amount || 1
        : this.planFiltered?.kind_configurations.PRE_PAYMENT.installment_amount || 1;
      return this.$i18n.t('modalBooking.alerts.prePaymentInstallmentAmount') + formatPrice(this.$i18n.t('payments.labels.currency'), prePaymentInstallmentAmount);
    },
    minimumAgeTooltipText() {
      return this.$i18n.t('modalBooking.alerts.minimumAgeForPixInstallment', { minAge: MIN_PIX_INSTALLMENT_AGE });
    },
    driverIsOlderToPixInstallment(){
      return this.$moment().diff(this.$moment(this.driverData.birthdate), 'years') >= MIN_PIX_INSTALLMENT_AGE;
    },
    expireDate(date, cycle) {
      return this.$moment(date).add(cycle, 'days').format('YYYY-MM-DD');
    },
    getCarCategoryByPlan(plan) {
      return this.planOptions?.find(planOpt => planOpt.id === plan)?.category;
    },
    handleOpen() {
      if (this.modal_data.id) {
        this.block_pending = this.modal_data.status === 'PENDING';
        this.block_active = this.modal_data.status === 'ACTIVE';
        this.block_closed = this.modal_data.status === 'CLOSED' || this.modal_data.status === 'CANCELED';
        this.data.id = this.modal_data.id;
        this.data.driver = this.modal_data.driver;
        this.data.has_recurrency = !!this.modal_data.recurrency;
        this.data.recurrency = this.modal_data.recurrency;
        this.data.plan = this.modal_data.plan;
        this.data.car = this.modal_data.car;
        this.data.transaction_method = this.modal_data.transaction_method;
        this.data.transaction_discount = this.modal_data.transaction_discount;
        this.data.pre_transaction_method = this.modal_data.pre_transaction_method;
        this.data.pre_transaction_discount = this.modal_data.pre_transaction_discount;
        this.data.started_at = this.$moment.utc(this.modal_data.started_at).toDate().toString();
        this.data.finished_at = this.modal_data.finished_at === null ? null : this.$moment.utc(this.modal_data.finished_at).toDate().toString();
        this.data.status = this.modal_data.status;
        this.modal.title = this.$i18n.t('modalBooking.texts.updateLocation');
        this.modal.save_button = this.$i18n.t('modalBooking.texts.saveUpdates');
        this.modal.cancel_button = this.$i18n.t('modalBooking.words.close');
        this.modal.close_booking_button = this.$i18n.t('modalBooking.texts.terminateLocation');
        this.modal.cancel_booking_button = this.$i18n.t('modalBooking.texts.cancelLocation');
        this.modal.filter_plan_text = { value: 'ACTIVE', name: 'ATIVO' };

        if (this.modal_data.pre_transaction_installments) {
          this.options_pre_transaction_installments = [{ value: this.modal_data.pre_transaction_installments, text: `${this.modal_data.pre_transaction_installments}x`}];
          this.data.pre_transaction_installments = this.modal_data.pre_transaction_installments;
        }

        if (this.modal_data.status === 'CLOSED') {
          this.modal.title = this.$i18n.t('modalBooking.texts.locationFinished');
          this.modal.cancel_button = this.$i18n.t('modalBooking.texts.closeWindow');
        }
        if (this.modal_data.status === 'CANCELED') {
          this.modal.title = this.$i18n.t('modalBooking.texts.locationCanceled');
          this.modal.cancel_button = this.$i18n.t('modalBooking.texts.closeWindow');
        }
        this.bookingDays = this.$moment(new Date()).utc().diff(this.$moment(this.modal_data.started_at), 'd');
        this.data.closingKind = '';
        this.data.openKind = '';
        if (this.modal_data.kind_closed_id) {
          this.getClosingKinds();
        }
        if (this.modal_data.kind_opened_id) {
          this.getOpenKinds();
        }
      } else {
        this.block_active = false;
        this.block_closed = false;
        this.data.id = null;
        this.data.driver = this.modal_data.driver;
        this.data.has_recurrency = true;
        this.data.recurrency = null;
        this.data.transaction_method = null;
        this.data.transaction_discount = 0;
        this.data.pre_transaction_method = null;
        this.data.pre_transaction_installments = 0;
        this.data.pre_transaction_discount = 0;
        this.data.plan = null;
        this.data.car = '';
        this.data.started_at = this.$moment().toDate();
        this.data.finished_at = null;
        this.data.status = null;
        this.planFiltered = {};
        this.selectPlanDisabled = false;
        this.carCategory = null;

        this.modal.title = this.$i18n.t('modalBooking.texts.newLocation');
        this.modal.save_button = this.$i18n.t('modalBooking.texts.startLocation');
        this.modal.cancel_button = this.$i18n.t('modalBooking.words.cancel');
      }
      this.booking_options_disabled = false;

      const { contract } = this.$route?.params
      if (contract && ['CLOSED', 'ACTIVE'].includes(this.modal_data?.status)) {
        setTimeout(() => {
          this.showDocumentsSection()
        }, 500)
      }
    },
    async doCreateBooking() {
      return this.$apollo
        .mutate({
          mutation: BOOKING_OPEN,
          variables: {
            kind_opened_id: this.data.kind_opened_id,
            driver: this.data.driver,
            plan: this.data.plan,
            car: this.data.car,
            has_recurrency: this.data.has_recurrency,
            transaction_method: this.data.transaction_method,
            transaction_discount: this.data.transaction_discount,
            pre_transaction_method: this.data.pre_transaction_method,
            pre_transaction_installments: this.data.pre_transaction_installments,
            pre_transaction_discount: this.data.pre_transaction_discount,
            started_at: this.$moment(this.data.started_at).utc().format('YYYY-MM-DD HH:mm:ss'),
            finished_at: null,
          },
        })
        .then(result => {
          if (result.data.openBooking) {
            this.$root.$emit('bv::hide::modal', 'modalBooking');
            this.$swal({
              type: 'success',
              title: this.$i18n.t('modalBooking.texts.phraseLocationCreatedSucessfull'),
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              window.location.reload();
            });
          }
        });
    },
    async doUpdateBooking() {
          return this.$apollo
            .mutate({
              mutation: BOOKING_UPDATE,
              variables: {
                id: this.data.id,
                driver: this.data.driver,
                plan: this.data.plan,
                car: this.data.car,
                recurrency: this.data.recurrency,
                has_recurrency: true,
                transaction_method: this.data.transaction_method,
                transaction_discount: this.data.transaction_discount,
                pre_transaction_method: this.data.pre_transaction_method,
                pre_transaction_installments: this.data.pre_transaction_installments,
                pre_transaction_discount: this.data.pre_transaction_discount,
                started_at: this.$moment(this.data.started_at).utc().format('YYYY-MM-DD HH:mm:ss'),
                finished_at: this.data.finished_at ? this.$moment(this.data.finished_at).utc().format('YYYY-MM-DD HH:mm:ss') : null,
              },
            })
            .then(result => {
              if (result.data.updateBooking) {
                this.$root.$emit('bv::hide::modal', 'modalBooking');
                this.$swal({
                  type: 'success',
                  title: this.$i18n.t('modalBooking.texts.phraseLocationUpdatedSuccessfull'),
                  showConfirmButton: false,
                  timer: 2500,
                }).then(() => {
                  window.location.reload();
                });
              }
            });
    },
    async doCloseBooking(reason) {
      const original_data = this.data;
      this.block_ui = true;
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title:
          this.data.finished_at === 'Invalid date' || this.data.finished_at === null
            ? this.$i18n.t('modalBooking.texts.phraseWishYouTerminateLocationToday')
            : this.$i18n.t('modalBooking.texts.areYouSure'),
        text:
          this.data.finished_at === 'Invalid date' || this.data.finished_at === null
            ? this.$i18n.t('modalBooking.texts.phraseYouDontDefineDateToday')
            : this.$i18n.t('modalBooking.texts.phraseYouWillTerminateDriverRent'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('modalBooking.words.confirm'),
        cancelButtonText: this.$i18n.t('modalBooking.words.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.$apollo
            .mutate({
              mutation: BOOKING_CLOSE,
              variables: {
                id: this.data.id,
                finished_at:
                  this.data.finished_at === null
                    ? this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
                    : this.$moment(this.data.finished_at).utc().format('YYYY-MM-DD HH:mm:ss'),
                kind_closed_id: reason.id,
              },
            })
            .then(() => {
              let item = [];

              if (this.driver.schedule) {
                item = this.driver.schedule.filter(obj => {
                  return obj.kind === 'DEVOLUTION' && obj.status === 'SCHEDULED';
                });
              }

              if (item.length) {
                return this.$apollo
                  .mutate({
                    mutation: FINALLY_SCHEDULE_DEVOLUTION,
                    variables: {
                      input: {
                        id: item[0].id,
                      },
                    },
                  })
                  .then(() => {
                    this.$root.$emit('bv::hide::modal', 'modalBooking');
                    this.$swal({
                      type: 'success',
                      title: this.$i18n.t('modalBooking.texts.phraseLocationFinishedSuccessfull'),
                      showConfirmButton: false,
                      timer: 2500,
                    }).then(() => {
                      window.location.reload();
                    });
                  })
                  .catch(() => {
                    return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.driverFinishError'));
                  });
              } else {
                window.location.reload();
              }
            })
            .catch(err => {
              let errorMessageKey = err.message.replace('GraphQL error: ', '');

              if (errorMessageKey === 'Booking was already closed') {
                errorMessageKey = 'bookingWasAlreadyClosed';
              }

              this.$swal({
                type: 'error',
                title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
                text: this.$i18n.t(`modalBooking.errors.${errorMessageKey}`),
                showConfirmButton: false,
                timer: 2500,
              }).then(() => {
                this.data = original_data;
                this.block_ui = false;
                window.location.reload();
              });
            });
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.block_ui = false;
          this.data = original_data;
        }
      });
    },
    getCurrentTimestamp() {
      const now = new Date();
      const pad = num => String(num).padStart(2, '0');
      return `${now.getFullYear()}${pad(now.getMonth() + 1)}${pad(now.getDate())}${pad(now.getHours())}${pad(now.getMinutes())}${pad(now.getSeconds())}`;
    },
    async doCreateOrUpdateBooking(){
      if (!this.data.id) return this.doCreateBooking();
      return this.doUpdateBooking();
    },
    getCreateOrUpdateBookingMessage(){
      const title = this.$i18n.t('modalBooking.texts.areYouSure')
      if (this.data.kind_opened_id && !this.data.id && this.isRegionBrazil()) return {
        title,
        text: this.$i18n.t(`modalBooking.texts.phraseYouWillCreateOrChangeDriverRentWithOpened.${this.openedKinds.find((kind) => kind.id == this.data.kind_opened_id).type}`),
      }
      return {
        title,
        text: this.data.id ? this.$i18n.t('modalBooking.texts.phraseYouWillChangeDriverRent') :
          this.$i18n.t('modalBooking.texts.phraseYouWillCreateDriverRent'),
      }
    },
    async openCompareSignature(e) {
      if (this.bookingSelect?.contract && this.bookingSelect?.driver?.cnh_doc?.url && this.isRegionBrazil()) {
        this.block_ui = true;
        await this.$refs.fleetCompareSignatures.handleSignatureCompare();
      } else if (!this.isRegionBrazil() || this.data.status !== 'PENDING') {
        await this.handleSubmit(e)
      }
    },
    async handleSubmit(e) {
      if (e) e.preventDefault();

      if (this.selectPlanDisabled) {
        window.analytics.track('SELECTED_LAST_BOOKING_PLAN', { plan: this.data });
      }
      this.saveClicked = true;
      if (
        !this.data.plan ||
        !this.data.pre_transaction_method ||
        !this.data.pre_transaction_installments ||
        !this.data.transaction_method ||
        !this.data.started_at ||
        (!this.data.kind_opened_id && this.isRegionBrazil())
      ) {
        return;
      }
      const original_data = this.data;
      this.block_ui = true;
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      await confirmationWindow({
        ...this.getCreateOrUpdateBookingMessage(),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('modalBooking.words.confirm'),
        cancelButtonText: this.$i18n.t('modalBooking.words.cancel'),
        reverseButtons: true,
      }).then(async ({value: confirmed}) => {
        if (confirmed) return this.doCreateOrUpdateBooking();
      })
      .catch(() => {
              this.$swal({
                type: 'error',
                title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
                text: this.$i18n.t('modalBooking.texts.checkLocationInfo'),
                showConfirmButton: false,
                timer: 2500,
              });
       })
      .finally(() => {
          this.block_ui = false;
          this.data = original_data;
      });
    },
    handleClose() {
      if (this.data.status === 'PENDING' && this.changeLinkCar) {
        window.location.reload();
      }
      this.$root.$emit('bv::hide::modal', 'modalBooking');
    },
    handlePrintContract() {
      if (this.isRegionBrazil()) {
        this.block_ui = true;
        const maxTentativas = 3; // tentativa inicial + 2 retentativas
        let tentativa = 0;

        const tentarCarregarContrato = async () => {
          try {
            const res = await this.$fleetRegulationProvider.get(
              `drivers/booking/${this.data.id}/contract`,
              { headers: { 'x-is-pdf': true, 'x-kovi-staff': this.koviStaff } }
            );

            this.block_ui = false;
            const pdf = `data:application/pdf;base64,${res.data.image}`;
            const fileName = `Contrato ${this.data.id}`;
            const pdfWindow = window.open();
            pdfWindow.document.write(
              `<html><head><title>${fileName}</title></head><body><embed width='100%' height='100%' src='${pdf}' type='application/pdf'></embed></body></html>`
            );
          } catch (err) {
            tentativa++;
            if (tentativa < maxTentativas) {
              console.log(`Tentativa ${tentativa} falhou, tentando novamente...`);
              return await tentarCarregarContrato();
            }

            this.block_ui = false;
            console.error('Erro ao carregar contrato após 3 tentativas:', err);
            this.$swal({
              type: 'error',
              title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
              text: this.$i18n.t('modalBooking.texts.errorLoadingContract'),
              showConfirmButton: false,
              timer: 2500,
            });
          }
        };

        return tentarCarregarContrato();
      }

      this.$nextTick(() => {
        let top = window.screen.height - 700;
        top = top > 0 ? top / 2 : 0;

        let left = window.screen.width - 1000;
        left = left > 0 ? left / 2 : 0;

        const printWindow = window.open('', this.$i18n.t('modalBooking.texts.printContract'), 'width=1000,height=700,top=' + top + ',left=' + left);
        printWindow.document.write(
          `<div style="font-family:Arial,sans-serif;font-size:30px;padding:200px 0;text-align:center">${this.$i18n.t(
            'modalBooking.texts.loadingContract'
          )}</div>`
        );
        printWindow.location.href = '/drivers/profile/' + this.data.driver + '/bookings/' + this.data.id + '/print';
        printWindow.moveTo(left, top);
        printWindow.focus();
      });
    },
    async handleCancelBooking() {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });
      confirmationWindow({
        title: this.$i18n.t('modalBooking.titles.areYouSure'),
        text: this.$i18n.t('modalBooking.texts.phraseYouWillCancelDriverRent'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('modalBooking.words.confirm'),
        cancelButtonText: this.$i18n.t('modalBooking.words.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value === true) {
          this.cancelBooking();
        }
      });
    },
    handleReasonClosingBooking(reason) {
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('modalBooking.titles.areYouSure'),
        html: `${this.$t('modalBooking.texts.phraseYouWillCancelDriverRentWithReason')} <h4>${reason.text}</h4>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('modalBooking.words.confirm'),
        cancelButtonText: this.$i18n.t('modalBooking.words.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.value === true) {
          this.doCloseBooking(reason);
        }
      });
    },
    handleOpenCancelBookingModal() {
      this.modalCancelBooking = true;
    },
    handleCloseCancelBooking() {
      this.modalCancelBooking = false;
    },
    async cancelBooking() {
      this.block_ui = true;

      return this.$apollo
        .mutate({
          mutation: BOOKING_CANCEL,
          variables: {
            id: this.data.id,
            driver: this.data.driver,
            canceled_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        })
        .then(() => {
          let scheduledDelivery = [];
          const checkBookingStatusPending = this.data.status === 'PENDING';

          if (this.driverData.schedule) {
            scheduledDelivery = this.driverData.schedule.filter(function (obj) {
              return obj.kind === 'DELIVERY' && obj.status === 'SCHEDULED';
            });
          }

          if (scheduledDelivery.length && checkBookingStatusPending) {
            this.$apollo
              .mutate({
                mutation: SCHEDULE_DELIVERY_CANCEL,
                variables: {
                  input: {
                    id: scheduledDelivery[0].id,
                  },
                },
              })
              .then(() => {
                window.location.reload();
              })
              .catch(err => {
                this.$swal({
                  type: 'error',
                  title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
                  text: err,
                  showConfirmButton: false,
                  timer: 2500,
                }).then(() => {
                  this.block_ui = false;
                });
              });
          } else {
            window.location.reload();
          }
        })
        .catch(err => {
          let alreadyClosed = false;
          let errorMessageKey = '';

          if (err) {
            errorMessageKey = err.message.replace('GraphQL error: ', '');
            errorMessageKey = errorMessageKey.replace('ParseError: ', '');

            if (errorMessageKey === 'Booking was already closed') {
              alreadyClosed = true;
              errorMessageKey = 'bookingWasAlreadyClosed';
            }
          }

          this.$swal({
            type: 'error',
            title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
            text: this.$i18n.t(`modalBooking.errors.${errorMessageKey}`),
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.block_ui = false;
            if (alreadyClosed) window.location.reload();
          });
        });
    },
    showDocumentsSection() {
      this.$root.$emit('bv::show::modal', 'docsUpload');
    },
    getClosingKinds() {
      this.load = true;
      makeQuery(GET_CLOSING_KINDS_BY_ID, {
        id: this.modal_data.kind_closed_id,
      })
        .then(res => {
          this.kindClosed = res.data.getBookingClosingKindById;
          this.getKindClosedTranslate();
        })
        .catch(err => {
          console.error(err);
        });
    },
    getOpenKinds() {
      this.load = true;
      makeQuery(GET_OPENING_KINDS_BY_ID, {
        id: this.modal_data.kind_opened_id,
      })
        .then(res => {
          this.kindOpened = res.data.getBookingOpeningKindById;
          this.data.kind_opened_id = this.modal_data.kind_opened_id;
          this.getKindOpenedTranslate();
        })
        .catch(err => {
          console.error(err);
        });
    },
    isRegionMexico() {
      return isMexico();
    },
    isRegionBrazil() {
      return isBrazil();
    },
    verifyCountry() {
      if (isMexico()) {
        this.region = 'es-mx';
      } else {
        this.region = 'pt-br';
      }
    },
    async getKindClosedTranslate() {
      try {
        if (this.modal_data.kind_closed_id && this.region) {
          this.kindClosedTranslate = await this.kindClosed.translate[this.region];
        }
      } catch {
        this.kindClosedTranslate = this.$i18n.t('modalBooking.words.reasonForClosingNotFound');
      }
    },
    async getKindOpenedTranslate() {
      try {
        if (this.modal_data.kind_opened_id && this.region) {
          this.kindOpenedTranslate = await this.kindOpened.translate[this.region];
        }
      } catch {
        this.kindOpenedTranslate = this.$i18n.t('modalBooking.words.reasonForClosingNotFound');
      }
    },
    filterPlan(item) {
      const statusFilter = this.planStatusFilters === 'ALL' || item.status === this.planStatusFilters;
      const productTypeFilter = this.planProductTypeFilters === 'ALL' || item.product_type === this.planProductTypeFilters;
      const categoryFilter = this.planCategoryFilters === 'ALL' || item.category === this.planCategoryFilters;
      const isZeroKmFilter = this.isZeroKmFilter ? item.is_zero_km : true;

      return statusFilter && productTypeFilter && categoryFilter && isZeroKmFilter;
    },
    orderedPlans() {
      const { items } = this.booking_options.plans;
      return [...items].map(item => ({...item, name: this.formatPlanName(item)})).filter(item => this.filterPlan(item));
    },
    changePlanStatusFilters(status) {
      this.planStatusFilters = status;
      this.planOptions = this.orderedPlans();
    },
    changePlanProductTypeFilters(productType) {
      this.planProductTypeFilters = productType;
      this.planOptions = this.orderedPlans();
    },
    changePlanCategoryFilters(category) {
      this.planCategoryFilters = category;
      this.planOptions = this.orderedPlans();
    },
    updateBookingOptions(selectedPlan, isCheckboxOption = false) {
      if (isCheckboxOption && !selectedPlan.id) {
        this.isPlanFromCheckboxOption = false
        this.data.plan = null;
        this.planFiltered = {};
        this.selectPlanDisabled = false;
        return;
      }
      if (isCheckboxOption && selectedPlan.id) {
        this.isPlanFromCheckboxOption = true
        this.selectPlanDisabled = true;
      }
      if (!selectedPlan?.id) return
      const plan = find(this.booking_options?.plans?.items, { id: selectedPlan?.id });
      this.planFiltered = plan;
      this.data.plan = plan.id;
      this.carCategory = plan.category;
      this.setAvailableFristPaymentMethods();
      this.isRTO(plan) ? this.setAvailablePrePaymentMethods('PRE_PAYMENT_RTO') : this.setAvailablePrePaymentMethods();
    },
    setAvailablePrePaymentMethods(kind = 'PRE_PAYMENT') {
      const availablePaymentMethods = new Set(this.kind_rules.filter(item => item.identifier === kind).map(item => item.value));
      this.options_pre_payment_methods =
        availablePaymentMethods.size === 0 || this.isRegionMexico()
          ? this.options_payment_methods
          : this.options_payment_methods.filter(method => availablePaymentMethods.has(method.type));

      if (isMexico()) {
        const oxxoMethod = this.options_pre_payment_methods.find(method => method.type === 'oxxo');
        if (!this.data.transaction_method) this.data.transaction_method = oxxoMethod?.value;
        if (!this.data.pre_transaction_method) {
          this.data.pre_transaction_method = oxxoMethod?.value;
          this.data.pre_transaction_installments = 1;
          this.block_installments = true;
        }
      }

      if (!isMexico()) {
        const pixMethod = this.options_pre_payment_methods.find(method => method.type === 'pix');
        if (!this.data.pre_transaction_method) this.setPaymentMethod(pixMethod.value);
        if (!this.data.transaction_method) this.data.transaction_method = pixMethod.value;
      }
    },
    setAvailableFristPaymentMethods(kind = 'FIRST_PAYMENT') {
      const availablePaymentMethods = new Set(this.kind_rules.filter(item => item.identifier === kind).map(item => item.value));
      this.options_frist_payment_methods =
        availablePaymentMethods.size === 0 || this.isRegionMexico()
          ? this.options_payment_methods
          : this.options_payment_methods.filter(method => availablePaymentMethods.has(method.type));
    },
    formatPlanNames(plans) {
      plans.forEach(plan => (plan.nameFormated = this.formatPlanName(plan)));
    },
    formatPlanName(plan) {
      return `${plan.name} (${plan.status}) ${this.getFirstPaymentValue(plan)} ${this.getRecurrenceValue(plan)}`;
    },
    generatePaymentOptions(paymentMethods) {
      return paymentMethods
        .map(payment_method => {
          switch (payment_method.type) {
            case 'credit_card':
              return this.generateCreditCardOption(payment_method);
            case 'boleto':
              return this.generateBoletoOption(payment_method);
            case 'oxxo':
              return this.generateOxxoOption(payment_method);
            case 'pix':
              return this.generatePixOption(payment_method);
            default:
              return null;
          }
        })
        .filter(Boolean);
    },
    generateCreditCardOption(payment_method) {
      return {
        value: payment_method.id,
        type: payment_method.type,
        text: `${this.$i18n.t('modalBooking.words.card')} ${payment_method.cc_brand.toUpperCase()} ${payment_method.cc_number}`,
      };
    },
    generateBoletoOption(payment_method) {
      return {
        value: payment_method.id,
        type: payment_method.type,
        text: this.$i18n.t('modalBooking.texts.boletoBank'),
      };
    },
    generateOxxoOption(payment_method) {
      return {
        value: payment_method.id,
        type: payment_method.type,
        text: this.$i18n.t('modalBooking.texts.oxxo'),
      };
    },
    generatePixOption(payment_method) {
      return {
        value: payment_method.id,
        type: payment_method.type,
        text: this.$i18n.t('modalBooking.texts.pix'),
      };
    },
    isDriverLicenseValid() {
      const { license_expired_at } = this.driverData;

      if (!license_expired_at || license_expired_at === '0000-00-00') {
        return false;
      }

      const driverLicenseExpiredAt = new Date(license_expired_at);
      const today = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(today.getDate() - 30);

      // License is valid if it's more than 30 days from expiration
      return driverLicenseExpiredAt >= thirtyDaysFromNow;
    },
    getRecurrenceValue(plan) {
      return (
        this.$i18n.t('modalBooking.words.recurrency') + formatPrice(this.$i18n.t('payments.labels.currency'), plan.kind_configurations?.RECURRENCY?.amount)
      );
    },
    getFirstPaymentValue(plan) {
      const kind = this.isRTO(plan) ? 'PRE_PAYMENT_RTO' : 'PRE_PAYMENT';
      const kindConfiguration = plan.kind_configurations[kind] || { amount: 0 };
      return this.$i18n.t('modalBooking.words.prePayment') + formatPrice(this.$i18n.t('payments.labels.currency'), kindConfiguration.amount);
    },
    onImageUpload($event) {
      if ($event.data?.updateBookingContract?.contract) {
        this.bookingSelect.contract = $event.data?.updateBookingContract?.contract;
      }
      if ($event.data?.updateDriverLicense?.cnh_doc?.url) {
        this.bookingSelect.driver.cnh_doc.url = $event.data?.updateDriverLicense?.cnh_doc?.url
      }
      this.images[$event.key].caption = $event.msg;
    },
    $_confirmLinkCar() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.titles.linkVehicle'),
        text: this.$i18n.t('modalVehicleDelivery.texts.phraseDoYouWihLinkThisVehicle'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.link'),
        confirmButtonClass: 'driver-container-link-car-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () =>
          new Promise((resolve, reject) => {
            if (!this.data.car) reject(this.$i18n.t('modalVehicleDelivery.texts.selectaVehicleToLink'));
            this.$_linkCar().then(resolve(true)).catch(reject(false));
          }),
      };

      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.theVehicleWasLinkedWithSuccessful'),
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
            text: '',
          };

          this.$swal(configSwalSuccess);
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'),
            showCancelButton: false,
            showConfirmButton: false,
            text: err,
            timer: 2000,
          };
          this.$swal(configSwalFail);
        });
    },
    $_linkCar() {
      if (!this.bookingSelect.id) return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));

      return this.$apollo
        .mutate({
          mutation: this.update_booking_mutation,
          variables: {
            id: this.bookingSelect.id,
            car: this.data.car,
            // schedule: this.deliveryScheduleId,
          },
        })
        .then(() => {
          this.modal_data.car = this.data.car
          this.changeLinkCar = true;
          return true;
        })
        .catch(() => {
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));
        });
    },
    $_genContract() {
      // update started at from booking for today
      this.$apollo.mutate({
        mutation: this.update_booking_mutation,
        variables: {
          id: this.bookingSelect.id,
          started_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        },
      });

      if (this.isRegionBrazil()) {
        this.block_ui = true;
        const maxTentativas = 3; // tentativa inicial + 2 retentativas
        let tentativa = 0;

        const tentarCarregarContrato = async () => {
          try {
            const res = await this.$fleetRegulationProvider.get(
              `drivers/booking/${this.bookingSelect.id}/contract`,
              { headers: { 'x-is-pdf': true, 'x-kovi-staff': this.koviStaff } }
            );

            this.block_ui = false;
            const pdf = `data:application/pdf;base64,${res.data.image}`;
            const fileName = `Contrato ${this.data.id}`;
            const pdfWindow = window.open();
            pdfWindow.document.write(
              `<html><head><title>${fileName}</title></head><body><embed width='100%' height='100%' src='${pdf}' type='application/pdf'></embed></body></html>`
            );
          } catch (err) {
            tentativa++;
            if (tentativa < maxTentativas) {
              console.log(`Tentativa ${tentativa} falhou, tentando novamente...`);
              return await tentarCarregarContrato();
            }

            this.block_ui = false;
            console.error('Erro ao carregar contrato após 3 tentativas:', err);
            this.$swal({
              type: 'error',
              title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
              text: this.$i18n.t('modalBooking.texts.errorLoadingContract'),
              showConfirmButton: false,
              timer: 2500,
            });
          }
        };

        return tentarCarregarContrato();
      }

      this.$nextTick(() => {
        let top = window.screen.height - 700;
        top = top > 0 ? top / 2 : 0;

        let left = window.screen.width - 1000;
        left = left > 0 ? left / 2 : 0;

        const printWindow = window.open('', this.$i18n.t('modalVehicleDelivery.texts.printContract'), 'width=1000,height=700,top=' + top + ',left=' + left);
        printWindow.document.write(
          `<div style="font-family:Arial,sans-serif;font-size:30px;padding:200px 0;text-align:center">${this.$i18n.t(
            'modalVehicleDelivery.texts.loadingContract'
          )}</div>`
        );
        printWindow.location.href = '/drivers/profile/' + this.data.driver + '/bookings/' + this.bookingSelect.id + '/print';
        printWindow.moveTo(left, top);
        printWindow.focus();
      });
    },
    unlinkCar() {
      if (!this.bookingSelect?.id) return

      return this.$apollo
        .mutate({
          mutation: this.unlinkCarMutation,
          variables: {
            input: {
              booking: this.bookingSelect?.id,
            },
          },
        })
        .then(() => {
          this.data.car = null;
          this.modal_data.car = null;
          this.modal_data.active_car = null;
          this.changeLinkCar = true;
          return true;
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <!-- Add Booking Modal -->
  <b-modal id="modalBooking"
           lazy
           no-fade
           :no-close-on-backdrop="block_ui"
           :no-close-on-esc="block_ui"
           :hide-header-close="block_ui"
           size="lg"
           :title="modal.title"
           @show="handleOpen"
           @hide="handleClose"
  >
    <b-container>
      <content-loading v-if="$apollo.queries.booking_options.loading"/>
      <b-form v-if="!$apollo.queries.booking_options.loading" name="booking">
        <b-form-row v-if="!isRegionMexico()">
          <b-col>
            <b-form-group>
              <div v-if="driverScheduleDelivery">
                <b-card :title="$i18n.t('modalBooking.texts.scheduledDeliveryResume')">
                  <b-card-text>
                    <strong>{{ $i18n.t('modalBooking.texts.scheduledDelivery.kind') }}:</strong> <br>
                    {{ $i18n.t('modalSchedule.type.' + driverScheduleDelivery.type) }}
                    <br>
                  </b-card-text>
                  <b-card-text>
                    <strong>{{ $i18n.t('modalBooking.texts.scheduledDelivery.comments') }}:</strong> <br>
                    {{ driverScheduleDelivery.comments }}
                    <br>
                  </b-card-text>
                  <b-card-text>
                    <strong>{{ $i18n.t('modalBooking.texts.scheduledDelivery.place') }}: </strong>
                    <br>
                    {{ schedulePlace.address.neighborhood }} ({{ schedulePlace.name }}) ({{
                      schedulePlace.address.street
                    }}, {{ schedulePlace.address.number }},
                    {{ schedulePlace.address.neighborhood }}, {{ schedulePlace.address.city }} -
                    {{ schedulePlace.address.state }})
                  </b-card-text>
                  <b-card-text>
                    <strong>{{ $i18n.t('modalBooking.texts.scheduledDelivery.date') }}: </strong>
                    <br>
                    {{ dateFormat(driverScheduleDelivery.scheduled_at) }}
                  </b-card-text>
                  <b-card-text>
                    <strong>{{ $i18n.t('modalBooking.texts.scheduledDelivery.status') }}: </strong>
                    <br>
                    {{ $i18n.t('modalBooking.texts.scheduledDeliveryStatuses.' + driverScheduleDelivery.status) }}
                  </b-card-text>
                </b-card>
              </div>
              <b-card v-else>
                <b-card-text>
                  {{ $i18n.t('modalBooking.texts.driverHasNotScheduledDelivery') }}
                </b-card-text>
              </b-card>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div>
          <b-form-row
            v-if="data.status !== 'PENDING' && data.status !== 'ACTIVE' && data.status !== 'CLOSED' && !isRegionMexico()">
            <last-booking-resume
              :driver-id="$route.params.id"
              :region="region"
              :use-last-plan="!(block_ui || block_active || block_closed)"
              @selected="event => updateBookingOptions(event, true)"
            />
          </b-form-row>

          <license-info-edit
            v-if="!['PENDING', 'CLOSED', 'CANCELED'].includes(data.status) && isRegionBrazil()"
            :enable-disable-save-button="enableDisableSaveButton"
            :driver-data="driverData"
          />

          <b-card>
            <b-form-row class="align-items-center">
              <b-form-group :label="$i18n.t('modalBooking.words.planFilter')" label-for="booking-plan" required
                            class="col-12 required"
              />

              <b-form-group :label="$i18n.t('modalBooking.words.planFilterStatus')" label-for="booking-plan" required
                            class="col-md-3 col-sm-12"
              >
                <b-form-select
                  id="booking-plan-status"
                  v-model="planStatusFilters"
                  :state="checkField(planStatusFilters)"
                  :disabled="block_ui || block_pending || block_active || block_closed || selectPlanDisabled"
                  :options="plan_status_filters"
                  @change="changePlanStatusFilters"
                />
              </b-form-group>

              <b-form-group :label="$i18n.t('modalBooking.words.planFilterProduct')" label-for="booking-plan" required
                            class="col-md-3 col-sm-12"
              >
                <b-form-select
                  id="booking-plan-product-type"
                  v-model="planProductTypeFilters"
                  :state="checkField(planProductTypeFilters)"
                  :disabled="block_ui || block_pending || block_active || block_closed || selectPlanDisabled"
                  :options="plan_products_types_filters"
                  @change="changePlanProductTypeFilters"
                />
              </b-form-group>

              <b-form-group :label="$i18n.t('modalBooking.words.planFilterCategory')" label-for="booking-plan" required
                            class="col-md-3 col-sm-12"
              >
                <b-form-select
                  id="booking-plan-category"
                  v-model="planCategoryFilters"
                  :state="checkField(planCategoryFilters)"
                  :disabled="block_ui || block_pending || block_active || block_closed || selectPlanDisabled"
                  :options="plan_categories_filters"
                  @change="changePlanCategoryFilters"
                />
              </b-form-group>

              <b-form-group label-for="booking-plan" required class="col-md-3 col-sm-12 pt-3">
                <b-form-checkbox ref="street_no_number" v-model="isZeroKmFilter" class="mt-1"
                                 :disabled="block_pending || block_active || block_closed"
                >
                  Somente 0KM
                </b-form-checkbox>
              </b-form-group>

              <b-form-group :label="$i18n.t('modalBooking.words.plan')" label-for="booking-plan" required
                            class="col-12 required"
              >
                <input-select-search
                  id="booking-plan"
                  value-field="id"
                  name="booking-plan"
                  placeholder="Selecione um Plano"
                  :options="planOptions"
                  :state="checkField(data.plan)"
                  :model="planFiltered.nameFormated"
                  :disabled="block_ui || block_pending || block_active || block_closed || selectPlanDisabled"
                  @selected="updateBookingOptions"
                />
                <div class="invalid-feedback">
                  {{ $i18n.t('modalBooking.texts.fieldRequired') }}
                </div>
              </b-form-group>
            </b-form-row>
          </b-card>

          <b-card>
            <b-form-row>
              <b-form-group :label="$i18n.t('modalBooking.texts.startDate')" label-for="booking-started" required
                            class="col-md-3 col-sm-12"
              >
                <date-picker
                  id="booking-started"
                  v-model="data.started_at"
                  :not-before="$moment().toDate().toString()"
                  :field-state="checkField(data.started_at)"
                  :hide="block_ui || block_active || block_closed"
                />
              </b-form-group>
              <b-form-group :label="$i18n.t('modalBooking.texts.endDate')" label-for="booking-finished" required
                            class="col-md-3 col-sm-12"
              >
                <date-picker
                  id="booking-finished"
                  v-model="data.finished_at"
                  :not-before="$moment().toDate().toString()"
                  :hide="block_ui || !block_active || block_closed"
                />
              </b-form-group>

              <b-form-group v-if="isRegionBrazil()" :label="$i18n.t('modalBooking.words.openKind')"
                            label-for="booking-open-kind"
                            class="col-md-6 col-sm-12 required"
              >
                <b-form-select
                  id="booking-open-kind"
                  v-model="data.kind_opened_id"
                  :disabled="block_ui || block_active || block_closed || block_pending"
                  :options="this.openKindOptions"
                  :required="isRegionBrazil()"
                  value-field="value"
                  :state="isValidOpenKind"
                  :class="isValidOpenKind && warnOpenKindMessage ? 'warn-border' : ''"
                  @change="onOpenKindChange($event)"
                />

                <div class="mt-3">
                  <small v-show="openingKindRule.length > 0">
                    <h6>Regras do motivo selecionado</h6>
                    <b-list-group>
                      <b-list-group-item v-for="(item, index) in openingKindRule" :key="index">
                        <strong>{{ $i18n.t('modalBooking.texts.openingRules.' + item.key) }}</strong>: {{ item.value }}
                      </b-list-group-item>
                    </b-list-group>
                  </small>
                </div>
                <small v-show="kindRuleLoading">Carregando regras...</small>
                <div v-if="isValidOpenKind && warnOpenKindMessage" class="pt-2 warn-label">
                  {{ warnOpenKindMessage }}
                </div>
                <div class="invalid-feedback">
                  {{ $i18n.t('modalBooking.texts.openKindFieldInvalid') }}
                </div>
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group :label="$i18n.t('modalBooking.texts.securityDeposit')" label-for="booking-pre-method"
                            class="col-md-4 col-sm-12">
                <b-form-select
                  id="booking-pre-method"
                  v-model="data.pre_transaction_method"
                  :state="checkField(data.pre_transaction_method)"
                  :disabled="block_ui || block_active || block_closed || isPlanFromCheckboxOption"
                  :options="options_pre_payment_methods"
                  @change="setPaymentMethod"
                />
                <div class="invalid-feedback">
                  {{ $i18n.t('modalBooking.texts.fieldRequired') }}
                </div>
              </b-form-group>
              <b-form-group :label="$i18n.t('modalBooking.words.installments')" label-for="booking-pre-installments"
                            required
                            class="col-md-2 col-sm-12 required"
              >
                <span
                  v-b-tooltip.right.hover.v-warning
                  :title="tooltipText"
                  :disabled="disableInstallmentTooltipText"
                >
                  <b-form-select
                    id="booking-pre-installments"
                    v-model="data.pre_transaction_installments"
                    :disabled="block_ui || block_installments || block_active || block_closed || isPlanFromCheckboxOption"
                    :state="checkField(data.pre_transaction_installments)"
                    :options="options_pre_transaction_installments"
                  />
                </span>
                <div class="invalid-feedback">
                  {{ $i18n.t('modalBooking.texts.fieldRequired') }}
                </div>
              </b-form-group>
              <b-form-group :label="$i18n.t('modalBooking.words.discount')" label-for="booking-pre-discount" required
                            class="col-md-2 col-sm-12"
              >
                <b-input-group>
                  <b-form-input id="booking-pre-discount" v-model="data.pre_transaction_discount" readonly
                                maxlength="2" type="text"
                  />
                  <b-input-group-text slot="append">
                    %
                  </b-input-group-text>
                </b-input-group>
              </b-form-group>
              <b-form-group :label="$i18n.t('modalBooking.texts.recurrencyRentPayment')"
                            label-for="booking-invoice-method" class="col-md-4 col-sm-12">
                <b-form-select
                  id="booking-invoice-method"
                  v-model="data.transaction_method"
                  :state="checkField(data.transaction_method)"
                  :required="isRegionBrazil()"
                  :disabled="block_ui || block_closed"
                  :options="options_frist_payment_methods"
                />
                <div class="invalid-feedback">
                  {{ $i18n.t('modalBooking.texts.fieldRequired') }}
                </div>
              </b-form-group>
            </b-form-row>
          </b-card>

          <b-card v-if="data.status === 'PENDING' || data.status === 'ACTIVE' || data.status === 'CLOSED'">
            <b-form-row>
              <b-form-group :label="$i18n.t('modalBooking.words.carFilter')" label-for="booking-car" required
                            class="col-12 required"
              />

              <b-form-group :label="$i18n.t('modalBooking.words.category')" label-for="booking-car" required
                            class="col-md-3 col-sm-12"
              >
                <b-form-select v-model="carCategory" :options="plan_categories_filters"
                               :disabled="block_ui || block_active || block_closed || !can_change_car_category">
                  <template slot="first">
                    <option :value="null" disabled>
                      {{ $i18n.t('modalBooking.words.selectCategory') }}
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group :label="$i18n.t('modalBooking.words.car')" label-for="booking-car"
                            class="col-md-9 col-sm-12 mb-0">
                <div v-if="data.status === 'ACTIVE' || data.status === 'CLOSED' || modal_data.active_car">
                  <b-input readonly :value="modal_data.active_car"/>
                </div>
                <div v-else>
                  <car-by-category-select
                    id="booking-car"
                    v-model="data.car"
                    :category="carCategory"
                    :plan-id="data.plan"
                    :plan-product-type="planFiltered?.product_type"
                    :plan-category="planFiltered?.category"
                    :plan="planFiltered"
                    :kind-opened="kindOpened"
                    :driver-work-city="driverData.work_city"
                    :disabled="block_ui || block_active || block_closed || modal_data.car"
                  />
                </div>
              </b-form-group>
              <b-col
                v-if="data.status === 'PENDING'"
                md="12" sm="12"
                class="mt-sm-3 mt-md-0"
              >
                <b-form-group label="">
                  <b-button v-if="!modal_data.car" id="driver-view-link-car-button" variant="success"
                            class="w-100" size="lg"
                            :disabled="!data.car" @click="$_confirmLinkCar"
                  >
                    {{ $i18n.t('modalVehicleDelivery.words.link') }}
                  </b-button>
                  <b-button v-if="modal_data.car" id="driver-view-link-generate-contract" class="mt-3 w-100"
                            variant="primary" size="lg" @click="$_genContract">
                    {{ $i18n.t('modalVehicleDelivery.texts.printContract') }}
                  </b-button>
                  <!-- @click="unlinkCar" -->
                  <b-button v-if="modal_data.car" id="driver-view-link-generate-contract" class="mt-3 w-100 white-color"
                            variant="warning" size="lg" @click="() => unlinkCar()"
                  >
                    {{ $i18n.t('modalVehicleDelivery.texts.unlinkCar') }}
                  </b-button>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-form-row v-if="modal_data.kind_closed_id && data.status === 'CLOSED'">
            <b-form-group :label="$i18n.t('modalBooking.words.reasonForClosing')" label-for="booking-reason-for-closing"
                          required
                          class="col-md-12 col-sm-12"
            >
              <b-input-group>
                <b-form-input id="booking-reason-for-closing" :value="kindClosedTranslate.toUpperCase()" readonly
                              type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-form-row>

          <b-form-row v-if="!!modal_data.kind_opened_id && kindOpenedTranslate">
            <b-form-group :label="$i18n.t('modalBooking.words.openKind')" label-for="booking-reason-for-open" required
                          class="col-md-12 col-sm-12"
            >
              <b-input-group>
                <b-form-input id="booking-reason-for-opening" :value="kindOpenedTranslate.toUpperCase()" readonly
                              type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-form-row>

          <b-form-row v-if="data.status === 'ACTIVE' || data.status === 'CLOSED' || data.status === 'CANCELED'">
            <b-form-group v-show="false" label-for="booking-recurrency" class="col-md-5 col-sm-12">
              <b-form-checkbox v-if="data.car" id="booking-recurrency" v-model="data.has_recurrency"
                               :disabled="block_ui" class="mt-1"
              >
                {{ $i18n.t('modalBooking.texts.recurrencyEnable') }}
              </b-form-checkbox>
            </b-form-group>
          </b-form-row>

          <div v-if="isRegionBrazil() && data.status === 'PENDING'" class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-4">
                  <b-card-text>
                    {{ $t('drivers.labels.docsLocation') }}
                  </b-card-text>
                </div>
              </div>
              <div v-if="getBookingSelectId && modal.save_button === $i18n.t('modalBooking.texts.saveUpdates')"
                   class="row">
                <div v-for="(item, index) in images" :key="index" :class="isRegionBrazil() ? 'col-4' : 'col-6 mb-2'">
                  <new-upload-image
                    :label="item.label" :caption="item.caption" :item="item"
                    :key-item="item.keyItem"
                    :file-upload-endpoint="item.endpoint" :file-upload-props="item.uploadProps"
                    @imageUpload="onImageUpload($event)"
                  />
                </div>
              </div>
            </div>
          </div>
          <b-alert v-if="block_ui && data.status === 'PENDING'" show variant="warning">
            <h4>
              Estamos analisando as assinaturas, aguarde ...
            </h4>
          </b-alert>
        </div>
      </b-form>

      <driver-license-alert :license_expired_at="driverData.license_expired_at"/>
    </b-container>

    <div slot="modal-footer" class="row btn-container">
      <b-button
        v-if="(data.status === 'ACTIVE' || data.status === 'CLOSED') && data.id"
        :disabled="block_ui"
        variant="link"
        class="print_contract"
        @click="handlePrintContract"
      >
        <i v-if="block_ui" class="fa fa-spin fa-refresh" />
        {{ $i18n.t('modalBooking.texts.printContract') }}
      </b-button>

      <b-button v-if="(data.status === 'ACTIVE' || data.status === 'CLOSED') && data.id" class="bg-warning"
                @click="showDocumentsSection">
        {{ $i18n.t('drivers.labels.showDocuments') }}
      </b-button>

      <b-button :disabled="block_ui" @click="handleClose">
        {{ modal.cancel_button }}
      </b-button>
      <b-button v-if="data.id && data.status === 'ACTIVE'" :disabled="block_ui" variant="danger"
                @click="handleOpenCancelBookingModal"
      >
        {{ modal.close_booking_button }}
      </b-button>
      <b-button
        v-if="data.status === 'PENDING'"
        :disabled="block_ui"
        variant="danger"
        @click="handleCancelBooking"
      >
        {{ modal.cancel_booking_button }}
      </b-button>
      <b-button
        v-if="!modal_data.status || !data.car || (canSaveBooking && ['ACTIVE', 'PENDING'].includes(modal_data.status))"
        :disabled="block_ui || (!block_pending && isRegionBrazil() && !isValidOpenKind) || cannotSave || block_ui_by_driver_ocr"
        :title="block_ui_by_driver_ocr ? 'Voce precisa salvar os dados da CNH' : ''"
        variant="success"
        @click="openCompareSignature"
      >
        <i v-if="block_ui" class="fa fa-spin fa-refresh" />
        {{ modal.save_button }}
      </b-button>
    </div>

    <modal-cancel-booking
      v-model="modalCancelBooking"
      :modal-data="modal_data"
      @closeModalCancelTransaction="handleCloseCancelBooking"
      @confirmCloseBooking="handleReasonClosingBooking"
      @errorCancelTransaction="handleCloseCancelBooking"
    />

    <modal-fleet-compare-signatures
      v-if="isRegionBrazil() && data.status === 'PENDING'"
      ref="fleetCompareSignatures"
      :driver-document-url="getDriverDocumentUrl"
      :contract-url="getContractUrl"
      :booking-id="bookingSelect.id"
      :driver-id="bookingSelect.driver.id"
      :can-open-modal-on-load="false"
      :handle-yes="handleSubmit"
    />
  </b-modal>
</template>

<style lang="scss" scoped>
.btn-container {
  button {
    margin: 10px;
  }

  .print_contract {
    border-color: #c8ced3;
    font-weight: bold;
  }

  .bg-warning {
    background-color: #ffc107;
  }
}

.warn-label {
  font-size: 0.8em;
  color: #ffc107;
}

.warn-border {
  border-color: #ffc107;
}

@media (max-width: 375px) {
  .btn-container {
    button {
      width: 100%;
    }
  }
}
</style>
